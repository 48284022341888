import React from 'react';
import '../mobileStyle/joblist.less';
import { useState } from 'react';
import arrow from '../assert/arrow@3x.png';
import JobMobile from './job';
import SelectorMobile from './selector';
import CommonModal from './commonModal';

export default function JobListMobile({ jobData, showID }) {
  if (!jobData || jobData.length === 0) {
    jobData = [];
  }
  let apartments = getUniqueStringArray(jobData.map((v) => v.apartment));
  let [currentApartment, setCurrentApartment] = useState('所有部门');
  const [currentJob, setCurrentJob] = useState(
      showID ? { link: `/${showID}` } : null);
  return (
      <div className="mobile-jobs">
        <div className="jobs-title">招聘职位</div>
        <SelectorMobile
            value={currentApartment}
            onChange={(e) => setCurrentApartment(e.target.value)
            }
        >
          {['所有部门'].concat(Object.keys(apartments)).map((v) => (
              <option
                  className="apartment"
                  key={v}
                  value={v}
              >
                {
                  `${v}${apartments[v] ?
                      `(${apartments[v]})` :
                      `(${jobData.length})`}`
                }
              </option>
          ))}
        </SelectorMobile>
        <div className="job-list">
          {getJobsByApartment(currentApartment, jobData).map((v) => (
              <span className="job-name" key={v.title}
                    onClick={() => setCurrentJob(v)}>
            <div>{v.title}</div>
            <div className="job-name-sub">{v.subtitle}</div>
            <img src={arrow} alt=""/>
          </span>
          ))}
        </div>
        <CommonModal open={currentJob !== null}
                     onClose={() => setCurrentJob(null)}
        >
          <JobMobile link={currentJob && currentJob.link}/>
        </CommonModal>
      </div>
  );
}

function getUniqueStringArray(arr) {
  let map = {};
  arr.forEach((v) => (map[v] > 0 ? (map[v] += 1) : (map[v] = 1)));
  return map;
}

function getJobsByApartment(apartment, jobs) {
  if (apartment !== '所有部门') {
    return jobs.filter((v) => v.apartment === apartment);
  } else {
    return jobs;
  }
}
