import React from "react";
import "../mobileStyle/presskit.less";
export default function PresskitMobile({
  title = "Writing about Luobulesi? Download logos and product shots for use in web and print media.",
  link = "#",
  btnText = "Download Press Kit",
  bgImg = "https://robloxdev.cn/beta_apk_assert/background/stripe_bg_long.png",
  title_en,
  btnText_en
}) {
  return (
    <div className="mobile-presskit" style={{
      backgroundImage: `url(${bgImg})`
    }}>
      <div className="presskit-text">{title} <br /> {title_en} </div>
      {/* <a href={link} className="btn-presskit">{btnText} <br /> {btnText_en} </a> */}
    </div>
  );
}
