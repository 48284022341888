import React, { useCallback, useRef } from 'react';
import SelectorMobile from './selector';
import '../mobileStyle/newsList.less';
import LoadingOverlay from '../component/loadingOverlay';

const SelectionKeyAll = 'all';
const SelectionKeyLuobu = 'luobu';
const SelectionKeyOthers = 'others';

const selections = [
  {
    key: SelectionKeyAll,
    label: '所有文章',
  },
  {
    key: SelectionKeyLuobu,
    label: 'By 罗布乐思',
  },
  {
    key: SelectionKeyOthers,
    label: 'By 其他作者',
  },
];

const scrollPadding = 100;

function genFilter(currentFilterKey) {
  if (currentFilterKey === SelectionKeyAll) {
    return () => true;
  } else if (currentFilterKey === SelectionKeyLuobu) {
    return (item) => item.author === '罗布乐思';
  } else return (item) => item.author !== '罗布乐思';
}

export default function NewsListMobile(
    { content, hasMore, pageIndex, authorFilter, onFetch, loading }
) {
  const filter = selections.find(item => item.key === authorFilter) ||
      selections[0];
  const newsListRef = useRef(null);

  const scrollToNewsListTop = useCallback(() => {
    if (!newsListRef || !newsListRef.current) {
      return;
    }
    const newsListTop = newsListRef.current.offsetTop;
    window.scrollTo({
      top: newsListTop - scrollPadding,
      left: 0,
      behavior: 'smooth'
    });
  }, [newsListRef]);
  const handleSelectorChange = (item) => {
    scrollToNewsListTop();
    onFetch(1, item.key);
  };

  const handleClickPrev = () => {
    if (pageIndex <= 1) {
      return;
    }
    scrollToNewsListTop();
    onFetch(pageIndex - 1, authorFilter);
  };
  const handleClickNext = () => {
    scrollToNewsListTop();
    onFetch(pageIndex + 1, authorFilter);
  };

  return <div className="news-list-mobile-component" ref={newsListRef}>
    <LoadingOverlay loading={loading}/>
    <div className="filter-container">
      <SelectorMobile onChange={(e) => handleSelectorChange(selections[e.target.value])}>
        {
          selections.map((item, index) =>
              <option key={item.key} value={index}>
                {item.label}
              </option>,
          )
        }
      </SelectorMobile>
    </div>
    <div className={loading ? 'list loading' : 'list'}>
      {
        content && content.filter(genFilter(filter.key)).map((item, index) =>
            <div className="item" key={index.toString()}>
              <div className="overlay"/>
              <a target="_blank" href={item.link} rel="noopener noreferrer">
                <h5 className="title">{item.title}</h5>
                <span className="author">by {item.author}</span>
                <span className="create-date">
                {item.createDate}
              </span>
              </a>
            </div>,
        )
      }
    </div>
    <div className="pagination">
      <button disabled={!hasMore} onClick={handleClickNext}>
        下一页
      </button>
      <button className="prev" disabled={pageIndex === 1}
              onClick={handleClickPrev}>
        上一页
      </button>
    </div>
  </div>;
}