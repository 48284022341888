import {useLayoutEffect} from 'react';
import {useLocation} from "react-router-dom";


export default function ScrollOnRouterChange({children}) {
  const location = useLocation();
  useLayoutEffect(() => {
    console.log('location changed');
    console.log(document.body.scrollHeight);
    setTimeout(()=>{
      window.scrollTo(0,0);
    }, 600); //todo very strange here if no timeout ,scroll actually do not work
  }, [location.pathname]);

  return children
}