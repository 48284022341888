import React from "react";
import Nav from "./nav";
export default function App({ activeTab,children,navConfig }) {
  return (
    <div id="page">
      <Nav/>
      {children}
    </div>
  );
}
