import React from 'react';
import BannerImage from './bannerImage';
import Contact from './contact';
import Presskit from './presskit';
import NewsList from './newsList';
import { PageSize, useNewsRequest } from '../hooks/newsList';

export default function NewsListPage() {
  const {
    handleFetch,
    data,
    newsInfo,
    pageIndex,
    authorFilter,
    loading,
  } = useNewsRequest(PageSize);

  const {
    bannerImage,
    bannerTitle,
    bannerTitle_en,
    presskit_bgImg,
    presskit_btnText,
    presskit_link,
    presskit_title,
    presskit_title_en,
    presskit_btnText_en,
  } = data;
  const { newsList, hasMore } = newsInfo;
  return <>
    <BannerImage imgLink={bannerImage} title={bannerTitle} title_en={bannerTitle_en}/>
    <NewsList content={newsList} hasMore={hasMore}
              pageIndex={pageIndex}
              loading={loading}
              authorFilter={authorFilter} onFetch={handleFetch}/>
    <Presskit title={presskit_title} title_en={presskit_title_en} bgImg={presskit_bgImg}
              btnText={presskit_btnText} btnText_en={presskit_btnText_en} link={presskit_link}/>
    <Contact/>
  </>;
}