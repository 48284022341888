import React from 'react';
import {Route, Redirect, Switch} from "react-router";
import {BrowserRouter} from 'react-router-dom'
import IndexPageMobile from "./indexPage";
import PageFrameMobile from "./pageFrame";
import TeamPageMobile from "./teamPage";
import CareerPageMobile from "./careerPage";
import NewsListPageMobile from './newsListPage';
import ScrollOnRouteChange from './scrollOnRouteChange';
import ArticlePage from './articlePage';


export default function Entry() {
  return <BrowserRouter>
    <ScrollOnRouteChange>
      <PageFrameMobile>
        <Switch>
          <Route path="/" exact component={IndexPageMobile}/>
          <Route path="/team" exact component={TeamPageMobile}/>
          <Route path="/career" exact component={CareerPageMobile}/>
          <Route path="/career/:id" exact component={CareerPageMobile}/>
          <Route path="/news" exact component={NewsListPageMobile}/>
          <Route path="/news/:id" exact component={ArticlePage}/>
          <Redirect to="/"/>
        </Switch>
      </PageFrameMobile>
    </ScrollOnRouteChange>
  </BrowserRouter>
}