import React from "react";
import "../style/contact.less";
import qrCode from "../assert/qr.jpg";
import robloxLogo from "../assert/Roblox_White_SMALL.png";
import tencentLogo from "../assert/tencent.png";

export default function Contact() {
  return (
    <div className="contact">
      <div className="logos">
        <a href="https://www.roblox.com/" rel="noopener noreferrer" target="_blank">
        <img src={robloxLogo} alt="" className="logo-roblox" />
        </a>
      </div>
      <div className="texts">
        <div className="contact-title">
          <a rel="noopener noreferrer" href="https://robloxdev.cn/creators.html" className="title-item">
            下载 Studio
          </a>
          <a rel="noopener noreferrer" href="http://robloxdev.cn" className="title-item">
            开发者网站
          </a>
          <a rel="noopener noreferrer" href="https://edu.roblox.qq.com/" className="title-item">
            罗布乐思教育
          </a>
          <div className="title-item qr-btn">
            微信公众号
            <img src={qrCode} alt="" />
          </div>
        </div>
        <div className="contact-desc">
          深圳市南山区海德一道88号中洲控股金融中心A座12层A单元 <br />
          12A, Tower A, No. 88 First Haide Road, Zhongzhou Holdings Financial Center, Nanshan District, Shenzhen, PRC.
        </div>
        <div className="contact-desc">
          ©2022 Roblox Corporation、Roblox、Roblox 标志及 Powering Imagination 是我们在美国及其他国家或地区的注册与未注册商标。
          <br />
          <a rel="noopener noreferrer" href="https://beian.miit.gov.cn/">
            粤ICP备20013629号
          </a>
        </div>
      </div>
    </div>
  );
}
