import React, {useEffect, useState} from "react";
import "../mobileStyle/index.less";
import BannerImageMobile from "./bannerImage";
import PeopleMobile from './people'
import ContactMobile from './contact'

export default function TeamPageMobile(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async function() {
      let pageNum = 0;
      let contentJson = [];
      while (true) {
        pageNum++;
        let result = await fetch(
          `/wp-json/wp/v2/posts?per_page=100&categories=4&page=${pageNum}`,
        );
        if (result.status != 200){
          break;
        }
        let jsonArray = await result.json();
        contentJson = contentJson.concat(jsonArray)
      }
      setData(contentJson);
    })();
  }, [setData]);
  
  let bannerImage = "https://corp.robloxdev.cn/wp-content/uploads/2021/12/newTeamImage.jpg";
  let peopleData = data;
  return (
    <>
      <BannerImageMobile
        imgLink={bannerImage}
        title={'罗布团队'}
        hasShadow={true}
      />
      <PeopleMobile
        peopleList={peopleData || []}
      />
      <ContactMobile />
    </>
  );
}
