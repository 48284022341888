import React from "react";
import "../mobileStyle/contact.less";
import { Link } from "react-router-dom";
let qrCode = "https://robloxdev.cn/beta_apk_assert/qr.jpg";
let robloxLogo = "https://robloxdev.cn/beta_apk_assert/Roblox_White_SMALL.png";
let tencentLogo = "https://robloxdev.cn/beta_apk_assert/tencent.png";

export default function Contact() {
  let ua = window.navigator.userAgent;
  if (/robloxapp/i.test(ua)) {
    return (
      <div className="mobile-contact">
        <div className="links">
          <div className="column">
            <a href="https://game.qq.com/contract.shtml" rel="noopener noreferrer" className="title-item" target="_blank">
              用户服务条款
            </a>
            <a href="https://game.qq.com/privacy_guide.shtml" rel="noopener noreferrer" className="title-item" target="_blank">
              腾讯游戏隐私保护指引
            </a>
          </div>
        </div>
        <div className="contents">
          <div className="logos">
            <a href="javascript:;" rel="noopener noreferrer">
              <img src={robloxLogo} alt="" className="logo-roblox" />
            </a>
          </div>
          <div className="texts">
            <div className="contact-desc">
                ©2022 Roblox Corporation、Roblox、Roblox 标志及 Powering Imagination 是我们在美国及其他国家或地区的注册与未注册商标。
              <br />
              <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">   
                粤ICP备20013629号
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mobile-contact">
      <div className="links">
        <div className="column">
          <div className="title">罗布乐思</div>
          <Link to="/" className="title-item" target="_blank">
            关于我们
          </Link>
          <Link to="/career" className="title-item" target="_blank">
            加入我们
          </Link>
          <a href="https://edu.roblox.qq.com/" rel="noopener noreferrer" className="title-item" target="_blank">
            教育官网
          </a>
        </div>
        <div className="column">
          <div className="title">产品服务</div>
          <a href="https://robloxdev.cn/playerapp" rel="noopener noreferrer" className="title-item" target="_blank">
            下载游戏客户端
          </a>
          <a href="https://robloxdev.cn/studio" rel="noopener noreferrer" className="title-item" target="_blank">
            下载Studio
          </a>
          {/* <a href="https://create.robloxdev.cn" className="title-item" target="_blank">
            创作中心
          </a> */}
        </div>
        <div className="column">
          <div className="title">资讯与资源</div>
          <a href="https://education.roblox.com/zh-cn/" rel="noopener noreferrer" className="title-item" target="_blank">
            教育中心
          </a>
          <a href="https://developer.roblox.com/en-us/" rel="noopener noreferrer" className="title-item" target="_blank">
            开发者中心
          </a>
          <a href="https://forum.robloxdev.cn/" rel="noopener noreferrer" className="title-item" target="_blank">
            中国开发者论坛
          </a>
        </div>
        <div className="column">
          <div className="title">其他</div>
          <a href="https://robloxdev.cn/user-help.html" rel="noopener noreferrer" className="title-item" target="_blank">
            帮助与支持
          </a>
          <a href="https://game.qq.com/contract.shtml" rel="noopener noreferrer" className="title-item" target="_blank">
            用户服务条款
          </a>
          <a href="https://game.qq.com/privacy_guide.shtml" rel="noopener noreferrer" className="title-item" target="_blank">
            腾讯游戏隐私保护指引
          </a>
          <a href="https://www.tencent.com/zh-cn/statement.html?/law/copyright.htm" rel="noopener noreferrer" className="title-item title-item-end" target="_blank">
            腾讯公司版权所有
          </a>
        </div>
        <div className="contact-icon">
          <a href="https://space.bilibili.com/402416759?from=search&seid=17513191545287343929" rel="noopener noreferrer" className="bilibili" target="_blank"></a>
          {/* <a href="https://forum.robloxdev.cn/t/topic/96" className="huya"></a> */}
          <div className="qr-btn wechat">
            <img src={qrCode} alt="" />
          </div>
        </div>
      </div>
      <div className="contents">
        <div className="logos">
          <a href="https://ieg.tencent.com/" rel="noopener noreferrer" target="_blank">
            <img src={tencentLogo} alt="" className="logo-tencent" />
          </a>
          <a href="https://roblox.com" rel="noopener noreferrer" target="_blank">
            <img src={robloxLogo} alt="" className="logo-roblox" />
          </a>
        </div>
        <div className="texts">
          <div className="contact-desc">
            COPYRIGHT © 1998 - 2019 TENCENT. ALL RIGHTS RESERVED.
            <br />
            <a href="https://www.qq.com/culture.shtml" target="_blank" rel="noopener noreferrer">
              粤网文[2017]6138-1456号
            </a>{" "}
            |{" "}
            <a href="https://game.qq.com/culture2.htm" target="_blank" rel="noopener noreferrer">
              （总）网出证（粤）字第057号
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
