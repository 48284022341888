import React, {useEffect, useState} from "react";
import "../mobileStyle/index.less";
import BannerImage from "./bannerImage";
import JobList from "./jobList";
import Benifit from "./benifit";
import Contact from "./contact";

export default function CareerPage() {
  const [data, setData] = useState({});

  useEffect(() => {
    (async function() {
      const result = await fetch(
        '/api-career/',
      );
      setData(await result.json());
    })();
  }, [setData]);
  let {
    job_list,
    bannerImage,
    bannerTitle,
  } = data;
  return (
    <>
      <BannerImage imgLink={bannerImage} title={bannerTitle || "虚位以待"} hasShadow={true} />
      <JobList jobData={job_list || []} />
      <Benifit />
      <Contact />
    </>
  );
}
