import React, {useEffect, useState} from "react";
import "../mobileStyle/index.less";
import BannerImageMobile from "./bannerImage";
import JobListMobile from "./jobList";
import BenifitMobile from "./benifit";
import ContactMobile from './contact';

export default function IndexPage(props) {
  const id = props.match.params.id;
  const [data, setData] = useState({});

  useEffect(() => {
    (async function () {
      const result = await fetch(
        '/api-career/',
      );
      setData(await result.json());
    })();
  }, [setData]);
  let {
    job_list,
    bannerImage,
    bannerTitle,
  } = data;
  return (
    <>
      <BannerImageMobile imgLink={bannerImage} title={bannerTitle || "虚位以待"} hasShadow={true}/>
      <JobListMobile jobData={job_list} showID={id}/>
      <BenifitMobile/>
      <ContactMobile/>
    </>
  );
}
