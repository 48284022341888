import React, {useEffect, useState} from "react";
import "../mobileStyle/job.less";
import bannerImg from "../assert/banner3.jpg";
import {pathJoin} from "../service/common";
export default function JobMobile({ link }) {

  const [job, setJob] = useState({});

  useEffect(() => {
    if (link) {
      (async function() {
        const result = await fetch(
          pathJoin(['/api-career/', link, '/'])
        );
        const {job} = await result.json();
        setJob(job);
      })();
    } else {
      setJob({});
    }
  }, [setJob, link]);

  const jobSafe = job || {};
  return (
    <div className="mobile-job">
      <div className="mobile-job-content">
        <div className="job-image" style={{backgroundImage: `url(${bannerImg})`}}>
          <div className="job-title">{jobSafe.title}</div>
          <div className="job-subtitle">{jobSafe.subtitle}</div>
          <div className="job-location">工作地点：{jobSafe.location}</div>
        </div>
        <div className="desc">
        <pre
          style={{ font: "inherit", whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: jobSafe.content }}
        ></pre>
          <div className="job-details">
            {jobSafe.link ? <a href={jobSafe.link} className="job-link">
              Apply Now
            </a> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
