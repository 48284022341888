export default [
  {
    "name":"首页 Home",
    "link":"/",
    "title": null,
  },{
    "name":"罗布团队 The Team",
    "link":"/team",
    "title": "罗布团队",
  },{
    "name":"加入我们 Join Us",
    "link":"/career",
    "title": "加入我们",
  },{
    "name":"新闻 News",
    "link":"/news",
    "title": "新闻",
  }
]