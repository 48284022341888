import React from "react";
import "../style/joblist.less";
import {useState} from "react";
import arrow from "../assert/arrow@3x.png";
import {Link} from "react-router-dom";
import {pathJoin} from "../service/common";

export default function JobList({jobData}) {
  if (!jobData || jobData.length === 0) {
    jobData = [];
  }
  let apartments = getUniqueStringArray(jobData.map((v) => v.apartment));
  let [currentApartment, setCurrentApartment] = useState("所有部门");
  return (
    <div className="jobs">
      <div className="jobs-title">招聘职位</div>
      <div className="apartment-list">
        {["所有部门"].concat(Object.keys(apartments)).map((v) => (
          <div
            className="apartment"
            key={v}
            onClick={() => setCurrentApartment(v)}
            style={{
              fontWeight: v === currentApartment ? "bold" : "normal",
            }}
          >
            {v}
            {apartments[v] ? `(${apartments[v]})` : `(${jobData.length})`}
          </div>
        ))}
      </div>
      <div className="seperate-line"></div>
      <div className="job-list">
        {getJobsByApartment(currentApartment, jobData).map((v) => (
          <Link to={pathJoin(['/career/', v.link])} key={v.link}>
            <div className="job-name">
              <div>{v.title}</div>
              <div className="job-name-sub">{v.subtitle}</div>
              <img src={arrow} alt=""/>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

function getUniqueStringArray(arr) {
  let map = {};
  arr.forEach((v) => (map[v] > 0 ? (map[v] += 1) : (map[v] = 1)));
  return map;
}

function getJobsByApartment(apartment, jobs) {
  if (apartment !== "所有部门") {
    return jobs.filter((v) => v.apartment === apartment);
  } else {
    return jobs;
  }
}
