import React from "react";
import "../style/learn.less";

export default function Learn({
  title,
  desc,
  items=[],
}) {
  return (
    <div className="learn">
      <div className="learn-title">{title}</div>
      <div className="learn-desc">{desc}</div>
      <div className="learn-list">
        {items.map((item, index) => {
          return <div className="learn-item" key={index.toString()}>
            <div className="image"><img src={item.img} alt=""/></div>
            <div className="title">{item.title}</div>
            <div className="content">{item.content}</div>
            <div className="detail-link">
              <a href={item.link}>了解更多 Learn More</a>
            </div>
          </div>;
        })}
      </div>
    </div>
  );
}
