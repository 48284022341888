import React from 'react';
import {Modal} from "react-responsive-modal";
import '../mobileStyle/commonModal.less'


export default function CommonModal(props) {
  const {children} = props;
  return <Modal
    {...props}
    classNames={{
      modal: "common-detail-modal",
      overlay: "common-detail-overlay",
    }}
  >
    { children }
  </Modal>
}