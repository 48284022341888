/**
 * @describe
 * Created by Kevin on 2020/11/19.
 */
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export const PageSize = 24;

export function useNewsRequest(
    initialPageSize) {
  const location = useLocation();
  const history = useHistory();
  const searchParam = new URLSearchParams(location.search);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [newsInfo, setNewsInfo] = useState({
    newsList: [],
    hasMore: true,
  });
  const pageSize = initialPageSize,
      pageIndex = searchParam.get('pageIndex') ?
          parseInt(searchParam.get('pageIndex')) :
          1,
      authorFilter = searchParam.get('authorFilter') || '';

  const newsRequest = useCallback(async (pageIndex, pageSize, authorFilter) => {
    let url = `/api-news/?pageNum=${pageIndex}&pageSize=${pageSize}`;
    if (authorFilter) {
      url += `&authorFilter=${authorFilter}`;
    }
    return (await fetch(
        url,
    )).json();
  }, []);

  useEffect(() => {
    (async function() {
      setLoading(true);
      const result = await newsRequest(pageIndex, pageSize, authorFilter);
      setData(result);
      setNewsInfo({
        hasMore: result.newsList.hasMore,
        newsList: result.newsList.content,
      });
      setLoading(false);
    })();
  }, [newsRequest, pageIndex, authorFilter, pageSize]);
  const handleFetch = useCallback(async (pageIndex, authorFilter) => {
    let newPath = `/news?pageIndex=${pageIndex}`;
    if (authorFilter) {
      newPath += `&authorFilter=${authorFilter}`;
    }
    history.push(newPath);
  }, [history]);

  return {
    handleFetch,
    pageIndex,
    authorFilter,
    loading,
    data,
    newsInfo,
  };
}