import React, {useEffect, useState} from "react";
import Article from "../component/article";

import Contact from "../component/contact";
import "../style/index.less";

export default function JobPage(props) {
  const id = props.match.params.id;
  const [data, setData] = useState({});

  useEffect(() => {
    (async function() {
      const result = await fetch(
        `/api-news/${id}/`,
      );
      setData(await result.json());
    })();
  }, [setData, id]);
  let {
    job,
  } = data;
  return (
    <>
      {job && <Article job={job} />}
      <Contact />
    </>
  );
}
