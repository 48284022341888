import React from "react";
import "../style/joinus.less";
export default function Joinus({
  title = "加入罗布乐思，与充满创意的人才携手开辟创新之路，展现个人价值。",
  link = "/career.html",
  btnText = "加入我们"
}) {
  return (
    <div className="joinus">
      <div className="joinus-text">{title}</div>
      <a href={link} className="btn-joinus">{btnText}</a>
    </div>
  );
}
