import React from "react";
import "../mobileStyle/corporate.less";
import bgImg from "../assert/tencentpartner.jpg";
import robloxLogo from "../assert/Roblox_White_SMALL.png"
import tencentLogo from "../assert/tencentpure.png"

export default function CorporateMobile({
  title = "罗布乐思是由腾讯互动娱乐与美国 Roblox 共同成立的合资公司。",
  logo1 = tencentLogo,
  logo2 = robloxLogo,
  background = bgImg
}) {
  return (
    <div className="mobile-corporate" style={{
      backgroundImage: `url(${background})`
    }}>
      <div className="shadow"></div>
      <div className="corporate-text">
        <div className="text-title">
          {title}
        </div>
        <div className="logos">
          <div className="logo-wrapper logo-tencent" >
            <img src={logo1} alt="" />
          </div>
          <div className="logo-wrapper logo-roblox">
            <img src={logo2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
