import React from "react";
import "../style/corporate.less";
import bgImg from "../assert/tencentpartner.jpg";
import robloxLogo from "../assert/Roblox_White_SMALL.png"
import tencentLogo from "../assert/tencentpure.png"

export default function Corporate({
  title = "罗布乐思是由腾讯互动娱乐与美国 Roblox 共同成立的合资公司。",
  logo1 = tencentLogo,
  logo2 = robloxLogo,
  background = bgImg
}) {
  return (
    <div className="corporate">
      <div className="corporate-image">
        <img src={background} alt="" />
      </div>
      <div className="shadow"></div>
      <div className="corporate-text">
        <div className="text-title">
          {title}
        </div>
        <div className="logos">
          <img src={logo1} alt="" className="logo-tencent" />
          <img src={logo2} alt="" className="logo-roblox" />
        </div>
      </div>
    </div>
  );
}
