import React from "react";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router';
import logoFile from "../assert/Roblox-Chinese-Logo-1557x537-White.png";
import baseNavConfig from "../config/nav";
import "../style/nav.less";
export default function Nav() {
  const location = useLocation();
  console.log(location.pathname);

  return (
    <div className="nav-bar">
      <ul id="menu-primary_menu" className="menu">
        {
          baseNavConfig.map((item) => {
            return <li className={`menu-item ${location.pathname === item.link ? 'current_page_item': ''}`} key={item.link}>
              <Link to={item.link}>{item.name}</Link>
            </li>
          })
        }
      </ul>
      <div className="logo">
        <a href="https://robloxdev.cn/">
          <img src={logoFile} alt="logo" />
        </a>
      </div>
    </div>
  );
}
