import React from "react";
import "../style/job.less";
import bannerImg from "../assert/banner3.jpg";
export default function Job({ job }) {
  return (
    <div className="job">
      <div className="job-image">
        <img src={bannerImg} alt="" />
        <div className="job-title">{job.title}</div>
        <div className="job-subtitle">{job.subtitle}</div>
        <div className="job-location">工作地点：{job.location}</div>
      </div>
      <div className="desc">
        <pre
          style={{ font: "inherit", whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: job.content.replace(/\\\"/g,'"') }}
        ></pre>
        <div className="job-details">
          {job.link ? <a href={job.link} className="job-link">
            Apply Now
          </a> : null}
        </div>
      </div>
    </div>
  );
}
