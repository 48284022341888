import React, { useEffect, useState } from "react";
import logoFile from "../assert/Roblox-Chinese-Logo-1557x537-White.png";
import closeFile from "../assert/close@3x.png";
import navLinkToggleFile from "../assert/menu@3x.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import "../mobileStyle/nav.less";
import { useLocation } from "react-router";
import baseNavConfig from "../config/nav";
import { Link } from "react-router-dom";

export default function NavMobile(props) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const location = useLocation();
  useEffect(() => {
    for (let item of baseNavConfig) {
      if (item.link === location.pathname) {
        setTitle(item.title);
      }
    }
  }, [setTitle, location]);
  let ua = window.navigator.userAgent;
  if (/robloxapp/i.test(ua)) {
    return (
      <div className="mobile-nav-bar">
        <div className="nav">
          <div className="logo">
            {title ? (
              <h1>{title}</h1>
            ) : (
              <a href="javascript:;">
                <img src={logoFile} alt="logo" />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mobile-nav-bar">
      <div className="nav">
        <div className="logo">
          {title ? (
            <h1>{title}</h1>
          ) : (
            <a href="https://robloxdev.cn/">
            <img src={logoFile} alt="logo" />
            </a>
          )}
        </div>
        <div className="nav-toggle">
          <img onClick={() => setCollapseOpen(!collapseOpen)} src={navLinkToggleFile} alt="toggle" />
        </div>
      </div>
      <Modal
        open={collapseOpen}
        classNames={{
          modal: "nav-menu-modal",
          overlay: "nav-menu-overlay",
        }}
        showCloseIcon={false}
      >
        <div className="nav-links" id="mobile-nav-links">
          <div className="operation-bar">
            <img src={closeFile} alt="" onClick={() => setCollapseOpen(false)} />
          </div>
          <ul className="menu" id="mobile-nav-menu">
            {baseNavConfig.map((item) => {
              return (
                <li className={`menu-item ${location.pathname === item.link ? "current_page_item" : ""}`} key={item.link}>
                  <Link to={item.link} onClick={() => setCollapseOpen(false)}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="nav-link">
            <a href="https://edu.robloxdev.cn/" target="_blank" rel="noopener noreferrer">
              教育官网
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}
