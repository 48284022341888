import React from "react";
import dropdown from '../assert/dropdown@3x.png';
import '../mobileStyle/selector.less'


export default function SelectorMobile({name, value, onChange, children}) {
  return <select name={name} className="mobile-selector"
          style={{backgroundImage: `url(${dropdown})`}}
          value={value}
          onChange={onChange}
  >
    {children}
  </select>
}
