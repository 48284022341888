import React from 'react';
import './App.less';
import EntryMobile from "./mobileComponent/entry";
import Entry from "./component/entry";
import EntryDispatcher from "./component/entryDispatcher";


function App() {
  return (
    <div className="App">
      <EntryDispatcher mobile={EntryMobile} desktop={Entry} pageProps={{}}/>
    </div>
  );
}

export default App;
