import React from "react";
import "../mobileStyle/job.less";
import bannerImg from "../assert/banner3.jpg";

export default function Job({ job }) {
  return (
    <div className="mobile-job">
      <div className="mobile-job-content">
        <div className="job-image" style={{ backgroundImage: `url(${bannerImg})` }}>
          <div className="job-title">{job.title}</div>
          <div className="job-subtitle">{job.subtitle}</div>
        </div>
        <div className="desc">
          <pre style={{ font: "inherit", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: job.content.replace(/\\\"/g, '"') }}></pre>
          {/* <div className="job-details">
            {job.link ? (
              <a href={job.link} className="job-link" style={{ background: "transparent", border: "1px solid rgba(255,255,255,.7)", borderRadius: 8 }}>
                更多资讯
              </a>
            ) : null}
          </div> */}
        </div>
      </div>
    </div>
  );
}
