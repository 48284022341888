import React from "react";
import "../style/presskit.less";
export default function Presskit({
  title = "Writing about Luobulesi? Download logos and product shots for use in web and print media.",
  title_en,
  link = "#",
  btnText = "Download Press Kit",
  bgImg = "https://robloxdev.cn/beta_apk_assert/background/stripe_bg_long.png",
  btnText_en,
}) {
  return (
    <div className="presskit">
      <div className="image"><img src={bgImg} alt=""/></div>
      <div className="presskit-text">{title} <br /> {title_en} </div>
      {/* <a href={link} className="btn-presskit">{btnText} <br /> {btnText_en} </a> */}
    </div>
  );
}
