import React, {useEffect, useState} from "react";
import "../mobileStyle/index.less";
import bannerImg from "../assert/mobile/banner.png";
import logotile from "../assert/tilt@3x.png";
import video from "../assert/output_2.mp4";
import BannerImageMobile from "./bannerImage";
import AttributeMobile from "./attributes";
import LearnMobile from "./learn";
import CorporateMobile from "./corporate";
import NewsMobile from './news'
import JoinUsMobile from "./joinus";
import ContactMobile from "./contact";
import EventBanner from "./eventBanner";

export default function IndexPageMobile() {
  const [data, setData] = useState({});

  useEffect(() => {
    (async function() {
      const result = await fetch(
        '/api/',
      );
      setData(await result.json());
    })();
  }, [setData]);
  let {data_event_banner = {}} = data;
  return (
    <>
      <BannerImageMobile
        imgLink={data.banner_img || bannerImg}
        videoLink={data.banner_video || video}
        logoLink={data.banner_logo || logotile}
        hasShadow={true}
        title={data.banner_title || "助力想象"}
        content={
          data.banner_content ||
          "罗布乐思致力于打造一个开发者和创作者的乐园。在这里，所有人都可以让想象力和创造力自由驰骋，与朋友们在探索的过程中发现无穷乐趣。"
        }
      />
      {/* <EventBanner 
        title={data_event_banner.title}
        background={data_event_banner.mobileBackground}
        eventBtnText={data_event_banner.eventBtnText}
        allEventBtnText={data_event_banner.allEventBtnText}
        eventLink={data_event_banner.eventLink}
        allEventLink={data_event_banner.allEventLink}
        line1={data_event_banner.line1}
        line2={data_event_banner.line2}
        line3={data_event_banner.line3}
        useDefault={data_event_banner.useDefault}
        htmlContent={data_event_banner.htmlContent}
      /> */}
      <AttributeMobile attributes={data.attributes || []}/>
      <LearnMobile
        title={data.learn_title || undefined}
        desc={data.learn_desc || undefined}
        items={data.learn_items || undefined}
      />
      <CorporateMobile
        title={data.corporate_title || undefined}
        logo1={data.corporate_logo1 || undefined}
        logo2={data.corporate_logo2 || undefined}
        background={data.corporate_bg || undefined}
      />
      <NewsMobile
        title={data.news_title || undefined}
        desc={data.news_desc || undefined}
        items={data.news_items || undefined}
      />
      <JoinUsMobile
        title={data.joinus_title || undefined}
        link={data.joinus_link || undefined}
        btnText={data.joinus_btnText || undefined}
      />
      <ContactMobile/>
    </>
  );
}

