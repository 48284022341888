import React from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../mobileStyle/attributes.less";
import experience from "../assert/mobile/experience@3x.png";
import imagine from "../assert/mobile/imagine@3x.png";
import community from "../assert/mobile/community@3x.png";
import arrowFile from '../assert/arrow@3x.png';

let placeholder = [
  {
    imagelink: experience,
    title: "3D 沉浸式体验",
    desc: "无论是探索主题乐园，还是感受校园生活，来这里就对了。"
  },
  {
    imagelink: imagine,
    title: "无限想象力",
    desc:
      "使用罗布乐思 Studio 实现创意，自由创作属于自己的个性作品。"
  },
  {
    imagelink: community,
    title: "开发者社区",
    desc: "创作者可以实现创作需求，并通过优秀的作品成为社区明星。"
  }
];

export default function AttributeMobile({attributes}) {
  let data = attributes.length
    ? attributes
    : placeholder;
  return (
    <div className="mobile-attributes">
      <Carousel
        showThumbs={false}
        showArrows={true}
        autoPlay={true}
        showStatus={false}
        interval={5000}
        infiniteLoop={true}
        renderArrowPrev={(clickHandler, hasPrev, label) =>
          <span className="i-control-arrow control-prev" onClick={clickHandler}>
          <img src={arrowFile}
                alt={label}/>
          </span>
        }
        renderArrowNext={(clickHandler, hasPrev, label) =>
          <span className="i-control-arrow control-next" onClick={clickHandler}>
          <img src={arrowFile}
               alt={label}/>
          </span>
        }
      >
        {data.map(v => (
          <div className="attribute" key={v.title}>
            <div className="attribute-image">
              <img src={v.imagelink} alt={v.title}/>
            </div>
            <div className="attribute-title">{v.title}</div>
            <div className="attribute-desc">{v.desc}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
