import React from "react";
import "../style/job.less";
import bannerImg from "../assert/banner3.jpg";
export default function Job({ job }) {
  return (
    <div className="job">
      <div className="job-image">
        <img src={bannerImg} alt="" />
        <div className="job-title">{job.title}</div>
        <div className="job-subtitle" style={{ color: "#BDBEBE", fontSize: 16 }}>
          {job.subtitle}
        </div>
      </div>
      <div className="desc">
        <pre style={{ font: "inherit", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: job.content.replace(/\\\"/g, '"') }}></pre>
        <div className="job-details">
          {job.link ? (
            <a href={job.link} className="job-link" style={{ background: "transparent", border: "1px solid rgba(255,255,255,.7)", borderRadius: 8 }}>
              更多资讯
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}
