import React, { useState} from "react";
import "../style/banner.less";
export default function BannerImage({
  imgLink,
  logoLink,
  title,
  title_en,
  content,
  videoLink,
  hasShadow
}) {
  let [isPlaying, setIsPlaying] = useState(false);
  function hideCover() {
    setIsPlaying(true);
  }
  return (
    <div className="banner-section">
      <div className="banner-image">
        {videoLink ? (
          <div className="video-banner">
            <img
              src={imgLink}
              alt=""
              className="video-cover"
              style={{
                display: isPlaying ? "none" : "block"
              }}
            />
            <video src={videoLink} autoPlay muted loop onPlaying={hideCover}></video>
          </div>
        ) : (
          <img src={imgLink} alt="" />
        )}
        {hasShadow ? <div className="shadow"></div> : null}
        <div className="banner-title">{title}<br />{title_en} </div>
      </div>
      {logoLink ? (
        <div className="banner-logo">
          <img src={logoLink} alt="" />
        </div>
      ) : null}
      {content ? <div className="banner-content">{content}</div> : null}
    </div>
  );
}
