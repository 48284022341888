import React from "react";
import arrowRight from '../assert/arrow@3x.png';
import "../style/news.less";

export default function News({
  title = "News Press",
  desc = "Our vision is to unite the world together through play. See how others write about us.",
  items = [
    {
      linkTitle: "by 178.com",
      content: "Roblox 中文名“罗布乐思”想象无界创造无限",
      link: "https://news.178.com/201909/362184415971.html",
      img: "https://robloxdev.cn/beta_apk_assert/background/stripe_bg_long.png",
    },
    {
      linkTitle: "by 游戏陀螺",
      content: "出海新选择：海外收入超 MC, 这款沙盒推出专项基金扶持国内开发者",
      link: "https://youxituoluo.com/523727.html",
      img: "https://robloxdev.cn/beta_apk_assert/background/stripe_bg_long.png",
    },
    {
      linkTitle: "by 36氪",
      content:
        "36氪专访 ｜ Roblox 段志云： 腾讯参投1.5亿美金，编程教育如何借游戏闭环？",
      link: "https://36kr.com/p/669173803276289",
      img: "https://robloxdev.cn/beta_apk_assert/background/stripe_bg_long.png",
    },
  ],
}) {
  return (
    <div className="news">
      <div className="news-title">{title}</div>
      <div className="news-desc">
        {desc}
      </div>
      <div className="news-more">
        <a href="/news">
          查看更多 <img className="news-more-icon" src={arrowRight} alt="查看"/>
        </a>
      </div>
      <div className="news-list">
        {items.map((item) => {
          return (
            <div className="news-item" key={item.link}>
              <div className="image">
                <img src={item.img} alt="" />
              </div>
              <div className="content">
                <a target="_blank" rel="noopener noreferrer" href={item.link}>{item.content}</a>
              </div>
              <div className="detail-link">
                <a target="_blank" rel="noopener noreferrer" href={item.link}>{item.linkTitle}</a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
