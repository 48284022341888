import React, { useEffect, useState} from "react";
import 'react-responsive-modal/styles.css';
import "../mobileStyle/people.less";
import wave from "../assert/bluewave.png";
import smoothscroll from 'smoothscroll-polyfill';
import CommonModal from "./commonModal";
import SelectorMobile from './selector';
let groupKeyOrderMap = {
  团队成员: 3,
  领导团队: 2,
  董事会成员: 4,
  中国领导团队: 1,
};
let groupTranslation = {
  团队成员: "The Team",
  领导团队: "The Leadership",
  中国领导团队 : "The Local Leadership",
  董事会成员: "The Board",
};
function getGroupKeyOrder(key) {
  return groupKeyOrderMap[key] || Infinity;
}
// kick off the polyfill!
smoothscroll.polyfill();

let offsetNav = 140;
export default function PeopleMobile({ peopleList }) {
  let groupMap = {};
  peopleList.forEach(v => {
    let hasKey = !!groupMap[v.group];
    if (!hasKey)
      groupMap[v.group] = {
        refs: [],
        position: Infinity,
        people: []
      };
  });
  let groupKeys = Object.keys(groupMap).sort((a, b) => getGroupKeyOrder(a) - getGroupKeyOrder(b));
  // peopleList.sort((a, b) => {
  //   return groupKeys.indexOf(a.group) - groupKeys.indexOf(b.group);
  // });
  peopleList.forEach(v => {
    v.alphabetical_order = v.alphabetical_order?.toLowerCase();
    groupMap[v.group].people.push(v);
  });
  groupKeys.forEach((key) => {
    groupMap[key].people = groupMap[key].people.sort((a, b) => {
      if(a.position_cn === "实习生"){
        return 1;
      }
      if(b.position_cn === "实习生"){
        return -1;
      }
      return a.alphabetical_order > b.alphabetical_order ? 1 : -1;
    });
  });
  let [currentPeople, setCurrentPeople] = useState(null);
  let [currentGroup, setCurrentGroup] = useState(groupKeys[0]);
  useEffect(() => {
    groupKeys.forEach(key => {
      groupMap[key].refs.forEach(ref => {
        groupMap[key].position = Math.min(
          ref.offsetTop,
          groupMap[key].position
        );
      });
    });
  });
  useEffect(() => {
    window.onscroll = function() {
      let y = window.scrollY;
      let position = getPosition(
        groupKeys.map(key => groupMap[key].position),
        y + offsetNav
      );
      if (
        y + document.documentElement.clientHeight >=
        document.body.offsetHeight - 30
      ) {
        setCurrentGroup(groupKeys[groupKeys.length - 1]);
      } else {
        setCurrentGroup(groupKeys[Math.max(0, position - 1)]);
      }
    };
    return () => {
      window.onscroll = null;
    }
  });
  function getPosition(arr, value) {
    for (var i = 0; i < arr.length; i++) {
      if (value < arr[i]) {
        return i;
      }
    }
    return i;
  }
  function jumpTo(key) {
    // window.scrollTo(
    //   0,
    //   groupMap[key].position - offsetNav
    // );
    window.scrollTo({
      top: groupMap[key].position - offsetNav,
      left: 0,
      behavior: 'smooth'
    });
  }
  return (
    <div className="mobile-people-wrapper">
      <div className="people">
        <div className="people-nav">
          <SelectorMobile value={currentGroup} onChange={(e) => jumpTo(e.target.value)}>
            {groupKeys.map(v => {
              return (
                  <option
                      key={v}
                      label={v + groupTranslation[v]}
                      value={v}
                  >
                    {v}
                  </option>
              );
            })}
          </SelectorMobile>
        </div>
        <div className="people-list">
          {groupKeys.map((key, index) => {
            return (
              <div className="people-group" key={key}>
                {index > 0 ? (
                  <div className="seperator"
                  ref={ref => {
                    groupMap[key].refs.push(ref);
                  }}
                  >
                    <img src={wave} alt="" />
                  </div>
                ) : null}
                {groupMap[key].people.map(people => {
                  return (
                    <div
                      className={"people-item t" + people.type}
                      key={people.name}
                      ref={ref => {
                        groupMap[people.group].refs.push(ref);
                      }}
                      onClick={() => (people.detail_cn || people.detail_en) ? setCurrentPeople(people) : null}
                    >
                      <div className="headshot">
                        <img src={people.headshot} alt={people.name} />
                      </div>
                      <div className="name">{people.name}</div>
                      <div className="textarea-more">
                        <div className="position">{people.position}</div>
                        {(people.detail_cn || people.detail_en) && (
                          <div
                            className="more"
                          >
                            了解更多 Learn More
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <CommonModal open={currentPeople !== null}
               onClose={() => setCurrentPeople(null)}
        >
          {
            currentPeople &&
            <div className="people-detail-wrapper">
              <div className="people-detail">
                <div className="info">
                  <div className="headshot">
                    <img src={currentPeople.headshot} alt={currentPeople.name} />
                  </div>
                  <div className="name">{currentPeople.name}</div>
                  <div className="position">{currentPeople.position_cn}</div>
                  <div className="position">{currentPeople.position_en}</div>
                </div>
                <div className="intro">
                <pre
                  style={{ font: "inherit", whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: currentPeople.detail_en }}
                ></pre>
                  <pre
                    style={{ font: "inherit", whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{ __html: currentPeople.detail_cn }}
                  ></pre>
                </div>
              </div>
            </div>
          }
        </CommonModal>
      </div>
    </div>
  );
}
