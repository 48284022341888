import React, {useEffect, useState} from "react";
import BannerImage from "../component/bannerImage";
import Slogan from "../component/Slogan";
import People from "../component/people";
import Contact from "../component/contact";
import "../style/index.less";

export default function TeamPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async function() {
      let pageNum = 0;
      let contentJson = [];
      while (true) {
        pageNum++;
        let result = await fetch(
          `/wp-json/wp/v2/posts?per_page=100&categories=4&page=${pageNum}`,
        );
        if (result.status != 200){
          break;
        }
        let jsonArray = await result.json();
        contentJson = contentJson.concat(jsonArray)
      }
      setData(contentJson);
    })();
  }, [setData]);

  let bannerImage = "https://corp.robloxdev.cn/wp-content/uploads/2021/12/newTeamImage.jpg";
  let peopleData = data;
  return (
    <>
      <BannerImage
        imgLink={bannerImage}
        hasShadow={true}
      />
      <People
        peopleList={peopleData || []}
      />
      <Contact />
    </>
  );
}
