import React from 'react';
import {Route, Redirect, Switch} from "react-router";
import {BrowserRouter} from 'react-router-dom'
import IndexPage from "./indexPage";
import PageFrame from "./pageFrame";
import TeamPage from "./teamPage";
import CareerPage from "./careerPage";
import JobPage from "./jobPage";
import NewsListPage from './newsListPage';
import ArticlePage from './articlePage';


export default function Entry() {
  return <BrowserRouter>
    <PageFrame>
      <Switch>
        <Route path="/" exact component={IndexPage}/>
        <Route path="/team" exact component={TeamPage}/>
        <Route path="/career" exact component={CareerPage}/>
        <Route path="/career/:id" exact component={JobPage}/>
        <Route path="/news" exact component={NewsListPage}/>
        <Route path="/news/:id" exact component={ArticlePage}/>
        <Redirect to="/"/>
      </Switch>
    </PageFrame>
  </BrowserRouter>
}