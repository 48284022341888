import React from "react";
import "../mobileStyle/banner.less";
export default function BannerImageMobile({
  imgLink,
  title,
  title_en,
  content,
  hasShadow
}) {
  return (
    <div className="mobile-banner-section mobile-content">
      <div className="banner-image">
        <div className="video-banner" style={{
          backgroundImage: `url("${imgLink}")`
        }}/>
        {hasShadow ? <div className="shadow"/> : null}
        <div className="banner-content-wrapper">
          <div className="banner-title">{title} <br />{title_en}</div>
          {content ? <div className="banner-content">{content}</div> : null}
        </div>
      </div>
    </div>
  );
}
