import React from 'react';

export default function EntryDispatcher ({desktop, mobile, pageProps}) {
  let isMobile = /android|iphone/i.test(window.navigator.userAgent);
  if (isMobile) {
    document.documentElement.style.fontSize =
      (((document.documentElement.clientWidth / 375) * 10) / 16) * 1000 + "%";
    document.documentElement.style.width = "100%";
    document.documentElement.style.minWidth = "auto";
    document.body.style.minWidth = "auto";
    document.body.style.width = "100%";
  }
  let Target = isMobile ? mobile : desktop;

  return <Target {...pageProps}/>
}
