import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../style/benifit.less";
import { Carousel } from "react-responsive-carousel";
import img1 from "../assert/IMG_2683.JPG";
import img2 from "../assert/IMG_2682.JPG";
import img3 from "../assert/IMG_2681.JPG";
// import img4 from "../assert/IMG_2684.JPG";
export default function Benifit() {
  return (
    <div className="benifit-wrapper">
      <div className="benifit">
        <div className="benifit-title">公司福利</div>
        <div className="benifit-image">
          <Carousel
            showThumbs={false}
            showArrows={false}
            autoPlay={true}
            showStatus={false}
            interval={5000}
            infiniteLoop={true}
          >
            <div className="image-slide">
              <img src={img1} alt="" />
            </div>
            <div className="image-slide">
              <img src={img2} alt="" />
            </div>
            <div className="image-slide">
              <img src={img3} alt="" />
            </div>
            {/* <div className="image-slide">
              <img src={img4} alt="" />
            </div> */}
          </Carousel>
        </div>
        <div className="benifit-desc-s">
          职业培训
          <br />
          灵活的工作时间
          <br />
          带薪年假
          <br />
          餐饮补贴
          <br />
          健身补贴
          <br />
          新人礼包
          <br />
          无限量供应茶水和零食
          <br />
          配备个人设备
          <br />
          升降桌椅
          <br />
          休息区
        </div>
      </div>
    </div>
  );
}
