import React from "react";
import NavMobile from "./nav";

export default function App({activeTab, children, navConfig}) {
  return (
    <div id="page">
      <NavMobile {...navConfig}/>
      {children}
    </div>
  );
}
