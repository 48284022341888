import React, { useState, useRef, useEffect, useCallback } from 'react';
import LoadingOverlay from './loadingOverlay';
import filterIcon from '../assert/filter@3x.png';
import '../style/newsList.less';
import '../style/popoverMenu.less';

const SelectionKeyAll = 'all';
const SelectionKeyLuobu = 'luobu';
const SelectionKeyOthers = 'others';

const selections = [
  {
    key: SelectionKeyAll,
    label: '所有文章',
  },
  {
    key: SelectionKeyLuobu,
    label: 'By 罗布乐思',
  },
  {
    key: SelectionKeyOthers,
    label: 'By 其他作者',
  },
];

function genFilter(currentFilterKey) {
  if (currentFilterKey === SelectionKeyAll) {
    return () => true;
  } else if (currentFilterKey === SelectionKeyLuobu) {
    return (item) => item.author === '罗布乐思';
  } else return (item) => item.author !== '罗布乐思';
}

const scrollPadding = 100;

/**
 * setFilter
 * onRequestNews
 */
export default function NewsList({
                                   content, hasMore, pageIndex, authorFilter, onFetch, loading,
                                 }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const filter = selections.find(item => item.key === authorFilter) ||
      selections[0];
  const wrapperRef = useRef(null);
  const newsListRef = useRef(null);

  const scrollToNewsListTop = useCallback(() => {
    if (!newsListRef || !newsListRef.current) {
      return;
    }
    const newsListTop = newsListRef.current.offsetTop;
    window.scrollTo({
      top: newsListTop - scrollPadding,
      left: 0,
      behavior: 'smooth'
    });
  }, [newsListRef]);

  const handleClickMenuItem = (item) => {
    //start from 1st page again
    scrollToNewsListTop();
    onFetch(1, item.key);
    setPopoverOpen(false);
  };

  const handleClickPrev = () => {
    if (pageIndex <= 1) {
      return;
    }
    scrollToNewsListTop();
    onFetch(pageIndex - 1, authorFilter);
  };
  const handleClickNext = () => {
    scrollToNewsListTop();
    onFetch(pageIndex + 1, authorFilter);
  };

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  useEffect(() => {
    /**
     * If clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return <div className="news-list-component" ref={newsListRef}>
    <LoadingOverlay loading={loading}/>

    <div className="filter-container">
      <div className="filter">
        <span className="filter-title"
              onClick={() => setPopoverOpen(!popoverOpen)}>
              {filter.label}
        </span>
        <img className="filter-icon"
             onClick={() => setPopoverOpen(!popoverOpen)}
             src={filterIcon} alt="筛选"/>
      </div>
      {
        popoverOpen && <div className="filter-popover" ref={wrapperRef}>
          <div className="popover-menu">
            {
              selections.map(item =>
                  <div key={item.key} onClick={() => handleClickMenuItem(item)}
                       className="popover-menu-item">
                    {item.label}
                  </div>,
              )
            }
          </div>
        </div>
      }
    </div>
    <div className={loading ? 'list loading' : 'list'}>
      {
        content && content.filter(genFilter(filter.key)).map((item, index) =>
            <div className="item" key={index.toString()}>
              <div className="overlay"/>
              <a target="_blank" href={item.link} rel="noopener noreferrer">
                <h5 className="title">{item.title}</h5>
                <span className="author">by {item.author}</span>
                <span className="create-date">
                {item.createDate}
              </span>
              </a>
            </div>,
        )
      }
    </div>
    <div className="pagination">
      <button disabled={!hasMore} onClick={handleClickNext}>
        下一页
      </button>
      <button className="prev" disabled={pageIndex === 1}
              onClick={handleClickPrev}>
        上一页
      </button>
    </div>
  </div>;
}