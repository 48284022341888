import React from "react";
import "../mobileStyle/learn.less";

export default function LearnMobile({
  title,
  desc,
  items=[],
}) {
  return (
    <div className="mobile-learn">
      <div className="learn-list">
        {items.map((item, index) => {
          return <div className="learn-item-wrapper" key={index.toString()}>
            {index === 0 && <div className="learn-title">{title}</div>}
            <div className="learn-item">
              <div className="image"><img src={item.img} alt=""/></div>
              <div className="title">{item.title}</div>
              <div className="content">{item.content}</div>
              <div className="detail-link">
                <a href={item.link}>了解更多 Learn More</a>
              </div>
            </div>
          </div>;
        })}
      </div>
    </div>
  );
}
